import { Helmet } from "react-helmet";
import Layout from "../../Layout";
import { useWindowResizeEffect } from "../../hooks/resizeHeight";
import Disclaimer from "../../components/disclaimer";

const Beer = () => {
  const height = useWindowResizeEffect();

  const myHtmlContent = `
  <p style="margin-bottom: 28px;">La munte, ai multă liniște și pace. În viața de zi cu zi, ai multă
  treabă și agitație.</p>
  <p style="margin-bottom: 28px;">Dar tocmai de asta am făcut berea Caraiman cu
  gust echilibrat, după o rețetă simplă și naturală: doar apă, malț
  din orz, orz și hamei. Ingrediente puține și motive mai multe să
  faci o pauză.</p>
  <p> Să simți și tu un pic din liniștea muntelui, oriunde
  te-ai opri să-ți tragi sufletul.</p>
`;

  const title = "Caraiman – gust echilibrat, numai bun să-ți tragi sufletul ";
  const description =
    "Descoperă rețeta naturală a berii Caraiman cu gust echilibrat, inspirat de liniștea muntelui, ca să-ți tragi sufletul oricând simți nevoia de o pauză.";

  return (
    <Layout>
      <Helmet>
        <title>Berea | {title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
      </Helmet>
      <div
        className="flex items-center justify-center relative w-full bg-cover bg-center bg-no-repeat z-1 py-20"
        style={{
          backgroundImage: "url('/images/berea/berea_background.png')",
          minHeight: height + 20,
        }}
      >
        <div className="bg-secondary-grey mx-8 md:mx-40 max-w-screen-xl">
          <div className="flex flex-col lg:flex-row items-center justify-center h-full max-w-screen-2xl m-6 md:m-20">
            <img
              className="w-36 md:w-64 custom-lg:w-[20rem] custom-xl:w-[30rem]"
              src="/images/berea/berea.png"
              alt="logo"
            />
            <div className="flex flex-col space-y-12 mx-">
              <div
                className="font-sans text-base md:text-base lg:text-[22px] lg:leading-8 font-normal text-primary-green text-left mt-8 lg:mt-0 md:min-w-80"
                dangerouslySetInnerHTML={{ __html: myHtmlContent }}
              />
            </div>
          </div>
        </div>
        <Disclaimer
          textColor="text-secondary-grey-light"
          shadow="tail-shadow"
          image="/images/icons2.png"
        />
      </div>
    </Layout>
  );
};

export default Beer;
