import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useFetchCities } from "../../mutations/get-cities";
import React, { useEffect } from "react";
import { City } from "../county-dropdown/county-dropdown";

interface CityMarkersProps {
  city: City | null;
  cityStores: City[];
}

interface MapComponentProps {
  city: City | null;
}

const CityMarkers = React.memo(({ city, cityStores }: CityMarkersProps) => {
  const map = useMap();

  useEffect(() => {
    if (city) {
      map?.panTo([city.latitude, city.longitude]);
    }
  }, [city, map]);

  return (
    <>
      {cityStores?.map((store, index) => (
        <Marker
          key={index}
          position={[store.latitude, store.longitude]}
          icon={
            new L.Icon({
              iconUrl:
                "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
              iconSize: [25, 41],
              iconAnchor: [12, 41],
            })
          }
        >
          <Popup>
            <div>
              <h3>{store.name}</h3>
              <p>{store.address}</p>
            </div>
          </Popup>
        </Marker>
      ))}
    </>
  );
});

const MapComponent: React.FC<MapComponentProps> = ({ city }) => {
  const { data: specificCity, error } = useFetchCities(
    city?.county ?? "",
    !!city?.county
  );

  if (error) {
    return null;
  }

  return (
    <MapContainer
      center={city ? [city.latitude, city.longitude] : [45.9432, 24.9668]}
      zoom={9}
      className="min-h-[500px] h-full w-full z-[45]"
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {city && <CityMarkers city={city} cityStores={specificCity} />}
    </MapContainer>
  );
};

export default MapComponent;
