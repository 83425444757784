interface Props {
  textColor: string;
  shadow?: string;
  image: string;
}

const Disclaimer = ({ textColor, shadow, image }: Props) => {
  return (
    <div className="absolute bottom-0 left-0 right-0 flex flex-row items-center justify-center p-2 md:justify-start z-20">
      <p
        className={`${textColor} ${shadow} text-xs md:text-xl font-sans font-semibold z-20`}
      >
        Consumă Caraiman în mod responsabil.
      </p>
      <img
        className={`${shadow} ml-2 w-16 lg:w-24 z-20`}
        src={image}
        alt="icons"
      />
    </div>
  );
};

export default Disclaimer;
