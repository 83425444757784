import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useWindowResizeEffect } from "../../hooks/resizeHeight";

interface NavbarProps {}

const Navbar: React.FC<NavbarProps> = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const height = useWindowResizeEffect();

  const routes = [
    { path: "/berea", name: "Berea" },
    { path: "/raftul", name: "Raftul" },
    { path: "/cabanierul", name: "Cabanierul" },
    { path: "/povestile", name: "Poveștile" },
    { path: "/promotia", name: "Promoția" },
    { path: "/contact", name: "Contact" },
  ];

  const specificRoutes = [
    {
      isLink: "/regulament-promotie",
      isExternalLink: "/regulament-promotie",
      name: "Regulament Promotie",
    },
    { isPdf: "/pdfs/TermsCond.pdf", name: "Termeni și Condiții" },
    {
      isPdf: "/pdfs/PrivacyPolicy.pdf",
      name: "Politica de Confidențialitate",
    },
    // { path: "/regulamente-campanii", name: "Regulamente Campanii" },
    {
      isExternalLink:
        "https://www.berariiromaniei.ro/codul-comunicarii-comerciale/",
      name: "Codul de Comunicare Comercială",
    },
    { isExternalLink: "https://anpc.ro/", name: "ANPC" },
  ];

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prevState) => {
      // Lock or unlock body scroll based on the new state
      lockBodyScroll(!prevState);
      return !prevState;
    });
  };

  const lockBodyScroll = (lock: boolean) => {
    if (lock) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  };

  useEffect(() => {
    // When the component mounts
    lockBodyScroll(mobileMenuOpen);

    // When the component unmounts
    return () => {
      lockBodyScroll(false);
    };
  }, [mobileMenuOpen]);

  return (
    <header id={"header"} className="relative">
      <nav
        className="flex flex-row justify-between mx-auto py-6 px-9 z-[100]"
        aria-label="Global"
      >
        <img
          className="absolute top-0 left-0 object-cover h-40 w-full z-[50]"
          src="/images/menu_background.png"
          alt="logo"
          width={window?.innerWidth}
          height={100}
        />
        <div className="flex items-center justify-start space-x-8 z-[50]">
          <button
            type="button"
            className="-m-2.5 rounded-md p-2.5 text-gray-700"
            onClick={toggleMobileMenu}
          >
            <div className="w-12">
              <img
                className={`h-${
                  mobileMenuOpen ? "8 spinner" : "6"
                }  w-auto mx-auto text-white`}
                src={
                  mobileMenuOpen
                    ? `/images/close.png?state=open`
                    : `/images/menu.png?state=closed`
                }
                alt={mobileMenuOpen ? "Close menu" : "Open menu"}
              />
            </div>
          </button>
          <Link to="/" className="mt-2">
            <span className="sr-only">Caraiman</span>
            <img
              className="h-12 min-[1250px]:h-20 ml-5 w-auto"
              src="/images/caraiman_logo.png"
              alt="caraiman logo"
            />
          </Link>
        </div>

        <div className="hidden lg:flex flex-row lg:gap-x-12 z-[50]">
          <ul className="flex flex-row space-x-2 items-center ml-8">
            {routes.map((route, index) => (
              <li
                className="flex flex-row items-center text-white font-bold max-[1500px]:text-xl text-[2rem] uppercase leading-6"
                key={route.path}
              >
                {index !== 0 && (
                  <img
                    className="h-auto w-8 mr-3.5 ml-4"
                    src="/images/menu_separator.png"
                    alt="separator"
                  />
                )}
                <Link to={route.path}>{route.name}</Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
      {mobileMenuOpen && (
        <div onClick={() => setMobileMenuOpen(false)}>
          <div className="fixed inset-0" />
          <div
            style={{ height: height + 120 }}
            className="fixed inset-y-0 left-0 w-full overflow-y-auto px-6 py-6 sm:max-w-md sm:ring-1 sm:ring-gray-900/10 bg-secondary-grey z-[49]"
          >
            <div className="pt-32 pb-8 flex flex-col justify-between md:h-full">
              <div className="lg:hidden">
                {routes.map((route) => (
                  <li
                    className="flex items-center text-primary-green font-bold text-3xl mt-5 uppercase"
                    key={route.path}
                  >
                    <Link to={route.path}>{route.name}</Link>
                  </li>
                ))}
              </div>
              <div className="lg:mt-36">
                {specificRoutes.map((route) => (
                  <li
                    className="flex items-center text-primary-green font-bold text-3xl mt-5 uppercase"
                    key={route.name}
                  >
                    {route.isExternalLink ? (
                      <a
                        href={route.isExternalLink}
                        target={route.isLink ? "_self" : "_blank"}
                        rel="noopener noreferrer"
                      >
                        {route.name}
                      </a>
                    ) : (
                      // For PDF links, use an anchor tag that points to the PDF path
                      <a href={route.isPdf} target="_blank" rel="noreferrer">
                        {route.name}
                      </a>
                    )}
                  </li>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Navbar;
