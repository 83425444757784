import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

export function useFetchCities(cityName?: string, enabled = false) {
  // Default parameter as null
  return useQuery({
    queryKey: ["cities", cityName ? { cityName } : ""], // Differentiate query key based on presence of cityName
    queryFn: () => {
      // Create the URL with the query string
      const url = new URL("https://api.caraiman.ro/locations");
      if (cityName) {
        url.searchParams.append("county", cityName); // Append only if cityName is provided
      }

      return fetch(url.toString()).then((response) => {
        if (!response.ok) {
          toast.error("Eroare la trimiterea mesajului", {
            position: "bottom-center",
          });
          throw new Error("Network response was not ok");
        }
        toast.success("Mesajul a fost trimis cu succes!", {
          position: "bottom-center",
        });
        return response.json();
      });
    },
    enabled,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
}
