import Select from "react-select";
import { City } from "../county-dropdown/county-dropdown";
import {
  customStyles,
  customStylesMobile,
} from "../../hooks/customStyleSelect";
import useDesktopCheck from "../../hooks/useDesktopCheck";

const NameDropdown = ({ setSelectedName, data }: any) => {
  const isDesktop = useDesktopCheck();
  const uniqueCounties = Array.from(
    new Set(data?.map((item: City) => item.name))
  ).sort();

  const options = uniqueCounties?.map((name) => ({
    value: name,
    label: name,
  }));

  const handleChange = (selectedOption: any) => {
    setSelectedName(selectedOption ? selectedOption.value : null);
  };

  return (
    <Select
      options={options}
      onChange={handleChange}
      className="w-full uppercase text-center"
      placeholder="Caută centru"
      isClearable={true}
      isSearchable={true}
      styles={isDesktop ? customStyles : customStylesMobile}
    />
  );
};
export default NameDropdown;
