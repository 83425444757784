import Layout from "../../Layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import loadGoogleMapsScript from "./loadGoogleMapsScript";
import { useWindowResizeEffect } from "../../hooks/resizeHeight";
import { Helmet } from "react-helmet";
import { useSendEmailMutation } from "../../mutations/contact-form";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Disclaimer from "../../components/disclaimer";

const validationSchema = Yup.object({
  name: Yup.string().required("Numele este obligatoriu."),
  company: Yup.string().required("Compania este obligatorie."),
  email: Yup.string()
    .email("Email invalid.")
    .required("Emailul este obligatoriu."),
  phone: Yup.string()
    .matches(/^(\+40|0)[0-9]{9}$/, "Numarul de telefon nu este valid.")
    .required("Telefonul este obligatoriu."),
  subject: Yup.string().required("Subiectul este obligatoriu."),
  message: Yup.string().required("Mesajul este obligatoriu."),
});

const Contact = () => {
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
  const mapRef = useRef(null); // Use a ref for the map container
  const height = useWindowResizeEffect();
  const { mutate } = useSendEmailMutation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const formik = useFormik({
    initialValues: {
      name: "",
      company: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    },

    validationSchema: validationSchema,

    onSubmit: async (values) => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }
      const token = await executeRecaptcha("submit");

      const emailObj = {
        name: values.name,
        company: values.company,
        email: values.email,
        phone: values.phone,
        subject: values.subject,
        message: values.message,
        recaptchaToken: token,
      };

      mutate(emailObj, {});
      if (formik.isValid) {
        formik.resetForm();
      }
    },
  });

  useEffect(() => {
    if ((window as any).google && (window as any).google.maps) {
      setIsGoogleMapsLoaded(true);
    } else {
      loadGoogleMapsScript("AIzaSyA7YcBGo23MjTpgXi1w9uDm46b3GDs-vR0")
        .then(() => {})
        .catch((error) => console.error("Error loading Google Maps:", error))
        .finally(() => {
          setIsGoogleMapsLoaded(true);
        });
    }
  }, []);

  useEffect(() => {
    if (isGoogleMapsLoaded && mapRef.current) {
      const mapPoint = { lat: 44.4884932, lng: 26.1269846 };

      const map = new (window as any).google.maps.Map(mapRef.current, {
        center: mapPoint,
        zoom: 16,
        streetViewControl: false,
        mapTypeControl: false,
        mapId: "131908f109d596d8",
      });

      new (window as any).google.maps.Marker({
        map: map,
        position: mapPoint,
        title: "Caraiman",
        icon: {
          path: (window as any).google.maps.SymbolPath.CIRCLE,
          fillColor: "#2b513a",
          fillOpacity: 1.0,
          scale: 10,
          strokeColor: "white",
          strokeWeight: 2,
        },
      });
    }
  }, [isGoogleMapsLoaded]);

  if (!isGoogleMapsLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  const title = "Contacteaza Caraiman";
  const description =
    "Contacteaza-ne pentru orice intrebare sau nelamurire. Caraiman";

  return (
    <Layout>
      <Helmet>
        <title>Contact | {title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
      </Helmet>
      <div className="relative flex flex-col lg:flex-row">
        <div
          style={{ minHeight: height + 20 }}
          className="order-2 lg:order-none	flex flex-col w-full lg:w-1/2 bg-secondary-grey pb-8"
        >
          <div className="flex flex-col px-6 lg:px-12  mt-6 lg:mt-20">
            <p className="font-bold text-primary-green text-xl md:text-2xl lg:text-4xl uppercase">
              Vrei să ne tragem sufletul la un pahar de vorbă? <br /> Scrie-ne
              sau dă-ne un telefon:
            </p>
            <span className="font-sans font-semibold text-primary-green md:text-xl lg:text-2xl uppercase mt-6">
              Adresa:
            </span>
            <span className="font-sans font-normal text-primary-green md:text-base lg:text-xl uppercase">
              BERGENBIER S.A. <br /> ȘOSEAUA BUCUREȘTI NORD, <br /> NR. 10,
              CLĂDIREA O1, ETAJ 5,
              <br /> VOLUNTARI, JUDEȚUL ILFOV <br />
              <b>TELEFON:</b> +40 372 20 71 09
            </span>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col space-y-2 pt-6 pb-10 lg:pb-14 px-6 lg:px-12 rounded font-sans"
          >
            {/* Name Field */}
            <div className="space-y-2 max-w-md">
              <div className="flex flex-row space-x-2">
                <input
                  id="name"
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  placeholder="Nume"
                  className={`placeholder-secondary-grey text-secondary-grey focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm bg-primary-green rounded-md px-4 py-2 ${
                    formik.touched.name && formik.errors.name
                      ? "border-red-500"
                      : ""
                  }`}
                />
                <p>*</p>
              </div>
              {formik.touched.name && formik.errors.name && (
                <span className="text-red-500 text-xs -mt-2">
                  {formik.errors.name}
                </span>
              )}
              {/* Company Field */}
              <div className="flex flex-row space-x-2">
                <input
                  id="company"
                  name="company"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company}
                  placeholder="Companie"
                  className={`placeholder-secondary-grey text-secondary-grey focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm bg-primary-green rounded-md px-4 py-2 ${
                    formik.touched.company && formik.errors.company
                      ? "border-red-500"
                      : ""
                  }`}
                />
                <p>*</p>
              </div>
              {formik.touched.company && formik.errors.company && (
                <span className="text-red-500 text-xs -mt-2">
                  {formik.errors.company}
                </span>
              )}
              {/* Email Field */}
              <div className="flex flex-row space-x-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  placeholder="Email"
                  className={`placeholder-secondary-grey text-secondary-grey  block w-full sm:text-sm bg-primary-green rounded-md px-4 py-2 ${
                    formik.touched.email && formik.errors.email
                      ? "border-red-500"
                      : ""
                  }`}
                />
                <p>*</p>
              </div>
              {formik.touched.email && formik.errors.email && (
                <span className="text-red-500 text-xs -mt-2">
                  {formik.errors.email}
                </span>
              )}
              {/* Phone Field */}
              <div className="flex flex-row space-x-2">
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  placeholder="Telefon"
                  className={`placeholder-secondary-grey text-secondary-grey focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm bg-primary-green rounded-md px-4 py-2 ${
                    formik.touched.phone && formik.errors.phone
                      ? "border-red-500"
                      : ""
                  }`}
                />
                <p>*</p>
              </div>
              {formik.touched.phone && formik.errors.phone && (
                <span className="text-red-500 text-xs -mt-2">
                  {formik.errors.phone}
                </span>
              )}
              {/* Subject Field */}
              <div className="flex flex-row space-x-2">
                <input
                  id="subject"
                  name="subject"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.subject}
                  placeholder="Subiect"
                  className={`placeholder-secondary-grey text-secondary-grey focus:ring-primary-green focus:border-primary-green block w-full sm:text-sm bg-primary-green rounded-md px-4 py-2 ${
                    formik.touched.subject && formik.errors.subject
                      ? "border-red-500"
                      : ""
                  }`}
                />
                <p>*</p>
              </div>
              {formik.touched.subject && formik.errors.subject && (
                <span className="text-red-500 text-xs -mt-2">
                  {formik.errors.subject}
                </span>
              )}
            </div>

            {/* Message Field */}
            <div className="flex flex-row space-x-2 max-w-xl">
              <textarea
                id="message"
                name="message"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
                placeholder="Mesaj"
                className={`min-h-10 placeholder-secondary-grey text-secondary-grey focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm bg-primary-green rounded-md px-4 py-2 ${
                  formik.touched.message && formik.errors.message
                    ? "border-red-500"
                    : ""
                }`}
              />
              <p>*</p>
            </div>
            {formik.touched.message && formik.errors.message && (
              <span className="text-red-500 text-xs -mt-2">
                {formik.errors.message}
              </span>
            )}
            <span className="md:ml-4 font-sans font-normal text-primary-green text-sm md:text-base">
              Câmpurile marcate cu * sunt obligatorii.
            </span>
            <button
              type="submit"
              className="w-max bg-secondary-grey-shadow hover:bg-secondary-grey text-primary-green font-bold text-xl py-1 px-4 rounded uppercase shadow-md"
              style={{ fontFamily: "Monea Alegante" }}
            >
              Trimite Mesajul
            </button>

            <ToastContainer />
          </form>
        </div>
        <div className="order-1 lg:order-none flex w-full lg:w-1/2 z-[40]">
          <div
            className="flex flex-grow h-[400px] lg:h-auto"
            ref={mapRef}
          ></div>
        </div>
        <Disclaimer
          textColor="text-primary-green"
          image="/images/icons-green.png"
        />
      </div>
    </Layout>
  );
};

export default Contact;
