import { useRef, useState } from "react";

const AgeModal = ({ onConfirm }: any) => {
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [rememberMe, setRememberMe] = useState(false); // Checkbox state

  const monthInputRef = useRef<HTMLInputElement>(null);
  const yearInputRef = useRef<HTMLInputElement>(null);

  const calculateAge = (dob: Date) => {
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const m = today.getMonth() - dob.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
    return age;
  };

  const handleDayChange = (e: any) => {
    const newDay = e.target.value;
    setDay(newDay);
    if (newDay.length === 2) {
      if (monthInputRef.current) {
        monthInputRef.current.focus();
      }
    }
  };

  const handleMonthChange = (e: any) => {
    const newMonth = e.target.value;
    setMonth(newMonth);
    if (newMonth.length === 2) {
      if (yearInputRef.current) {
        yearInputRef.current.focus();
      }
    }
  };

  const handleYearChange = (e: any) => {
    const newYear = e.target.value;
    setYear(newYear);
  };

  const handleVerify = (event: any) => {
    event.preventDefault();
    if (!day || !month || !year) {
      alert("Please fill in all fields.");
      return;
    }

    const intDay = parseInt(day, 10);
    const intMonth = parseInt(month, 10) - 1; // JavaScript months are 0-indexed.
    const intYear = parseInt(year, 10);

    if (intDay < 1 || intDay > 31) {
      alert("Please enter a valid day.");
      return;
    }
    if (intMonth < 0 || intMonth > 11) {
      alert("Please enter a valid month.");
      return;
    }
    if (intYear < 1900 || intYear > new Date().getFullYear()) {
      alert("Please enter a valid year.");
      return;
    }

    const dob = new Date(intYear, intMonth, intDay);
    // Checking if the date was correctly constructed (invalid dates like 31st of Feb turn into March)
    if (
      dob.getDate() !== intDay ||
      dob.getMonth() !== intMonth ||
      dob.getFullYear() !== intYear
    ) {
      alert("Invalid date. Please enter a real date.");
      return;
    }

    const age = calculateAge(dob);
    if (age < 18) {
      //   onConfirm(false, null);
      window.location.href = "https://www.prieteniresponsabili.ro/despre-noi";
      return;
    } else {
      // Age is 18 or above, and the date is valid, proceed with confirmation
      if (rememberMe) {
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 30);
        const ageVerificationData = {
          isVerified: true,
          expiry: expiryDate.getTime(),
          dateOfBirth: dob.toISOString(),
        };
        localStorage.setItem(
          "ageVerified",
          JSON.stringify(ageVerificationData)
        );
      }
      onConfirm(true, dob);
    }
  };

  return (
    <div className="bg-secondary-grey z-[100] absolute flex flex-col items-center py-14 md:py-20 px-8 md:px-20 top-20 m-16 md:m-0">
      <h2 className="text-3xl md:text-5xl text-center text-primary-green uppercase font-bold">
        Ne pare rău, <br />
        Nu te putem lua pe traseu.
      </h2>
      <p className="text-xl md:text-3xl text-center text-primary-green uppercase font-bold">
        Te așteptăm după ce faci 18 ani.
      </p>
      <form
        onSubmit={handleVerify}
        className="flex flex-col items-center mt-12 z-50"
      >
        <div className="flex mb-4 space-x-6 md:space-x-12">
          <input
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            placeholder="ZZ"
            value={day}
            onChange={handleDayChange}
            maxLength={2}
            className="custom-number-input p-2 border-b-2 rounded-none border-primary-green font-sans text-2xl md:text-4xl max-w-16 md:max-w-32 text-center text-primary-green placeholder:text-primary-green bg-transparent focus:outline-none focus:ring-0 focus:border-primary-green"
          />
          <input
            ref={monthInputRef}
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            placeholder="LL"
            value={month}
            onChange={handleMonthChange}
            maxLength={2}
            className="custom-number-input p-2 border-b-2 rounded-none border-primary-green font-sans text-2xl md:text-4xl max-w-16 md:max-w-32 text-center text-primary-green placeholder:text-primary-green bg-transparent focus:outline-none focus:ring-0 focus:border-primary-green"
          />
          <input
            ref={yearInputRef}
            type="number"
            pattern="[0-9]*"
            inputMode="numeric"
            placeholder="AAAA"
            value={year}
            onChange={handleYearChange}
            maxLength={4}
            className="custom-number-input p-2 border-b-2 rounded-none border-primary-green font-sans text-2xl md:text-4xl max-w-24 md:max-w-44 text-center text-primary-green placeholder:text-primary-green bg-transparent focus:outline-none focus:ring-0 focus:border-primary-green"
          />
        </div>
        <button
          type="submit"
          className="mt-2 py-2 px-16 uppercase text-3xl font-bold text-secondary-grey bg-primary-green"
        >
          Continuă
        </button>
        <div className="flex items-center mt-4">
          <input
            id="rememberMe"
            type="checkbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
            className="hidden" // Hide the original checkbox
          />
          <div
            className={`w-6 h-6 flex justify-center items-center mr-2 border-2 rounded ${
              rememberMe
                ? "border-primary-green bg-transparent"
                : "border-primary-green"
            }`}
            onClick={() => setRememberMe(!rememberMe)}
          >
            {rememberMe && (
              <svg
                className="w-4 h-4 text-primary-green"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
          <label
            htmlFor="rememberMe"
            className="text-sm text-primary-green font-sans cursor-pointer"
          >
            Ține-mă minte pentru 30 de zile.
          </label>
        </div>
      </form>
    </div>
  );
};

export default AgeModal;
