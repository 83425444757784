import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface PartnerData {
  nume: string;
  prenume: string;
  email: string;
  telefon: string;
  cod: string;
  judet: string;
}

export const useSubmitPartnerData = () => {
  return useMutation({
    mutationFn: async (partnerData: PartnerData) => {
      const paramsData: Record<string, string> = {
        nume: partnerData.nume,
        prenume: partnerData.prenume,
        email: partnerData.email,
        telefon: partnerData.telefon,
        cod: partnerData.cod,
        judet: partnerData.judet,
      };

      const urlParams = new URLSearchParams(paramsData).toString();
      const url = `https://apps.yet.ro/api/parteneri/caraiman/ncp2024?${urlParams}`;

      const username = "bergenbier";
      const password = "1Hy$3sR3PfeX";
      const auth = btoa(`${username}:${password}`); // Encode credentials

      return fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Basic ${auth}`,
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (!response.ok) {
          toast.error("Failed to submit data", {
            position: "bottom-center",
          });
          throw new Error("Failed to submit data");
        }
        toast.success("Data submitted successfully!", {
          position: "bottom-center",
        });
        return response.json();
      });
    },
  });
};
