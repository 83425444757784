import { useState, useEffect } from "react";
import Select from "react-select";
import {
  customStyles,
  customStylesMobile,
} from "../../hooks/customStyleSelect";
import useDesktopCheck from "../../hooks/useDesktopCheck";

export interface City {
  name: string;
  code: string;
  latitude: number;
  longitude: number;
  county: string;
  city: string;
  area: string;
  address: string;
}

const CountyDropdown = ({ setSelectedCounty, data }: any) => {
  const isDesktop = useDesktopCheck();

  const [displayCities, setDisplayCities] = useState<City[]>([]);

  useEffect(() => {
    const uniqueCitiesMap = new Map();

    data?.forEach((city: City) => {
      const countyKey = city.county.toLowerCase();
      if (!uniqueCitiesMap.has(countyKey)) {
        uniqueCitiesMap.set(countyKey, city);
      }
    });

    const uniqueCities = Array.from(uniqueCitiesMap.values()).sort((a, b) =>
      a.county.localeCompare(b.county)
    );

    setDisplayCities(uniqueCities);
  }, [data]);

  const options = displayCities?.map((city) => ({
    value: city,
    label: city.county,
  }));

  const handleChange = (selectedOption: any) => {
    setSelectedCounty(selectedOption ? selectedOption.value : null);
  };

  return (
    <Select
      options={options}
      onChange={handleChange}
      className="w-full uppercase text-center"
      placeholder="Caută județ"
      isClearable={true}
      isSearchable={true}
      styles={isDesktop ? customStyles : customStylesMobile}
    />
  );
};

export default CountyDropdown;
