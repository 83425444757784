import { useEffect, useState } from "react";
import CountyDropdown, {
  City,
} from "../../components/county-dropdown/county-dropdown";
import Layout from "../../Layout";
import { Helmet } from "react-helmet";
import { useWindowResizeEffect } from "../../hooks/resizeHeight";
import Disclaimer from "../../components/disclaimer";
import useDesktopCheck from "../../hooks/useDesktopCheck";
import { useFetchCities } from "../../mutations/get-cities";
import CityDropdown from "../../components/city-dropdown";
import NameDropdown from "../../components/name-dropdown";

const title = "Caraiman – gust echilibrat, numai bun să-ți tragi sufletul ";
const description =
  "Descoperă rețeta naturală a berii Caraiman cu gust echilibrat, inspirat de liniștea muntelui, ca să-ți tragi sufletul oricând simți nevoia de o pauză.";

const CenterLocations = () => {
  const { data } = useFetchCities("", true);
  const height = useWindowResizeEffect();
  const isDesktop = useDesktopCheck();

  const [selectedCounty, setSelectedCounty] = useState<City | null>(null);
  const [selectedCity, setSelectedCity] = useState<any | null>(null);
  const [selectedName, setSelectedName] = useState<any | null>(null);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredNames, setFilteredNames] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);

  useEffect(() => {
    if (selectedCounty) {
      const newFilteredCities = data.filter(
        (item: any) => item.county === selectedCounty.county
      );
      setFilteredCities(newFilteredCities);
    } else {
      setFilteredCities(data);
    }
  }, [selectedCounty, data]);

  useEffect(() => {
    if (selectedCounty && selectedCity) {
      const newFilteredNames = data.filter(
        (item: any) =>
          item.county === selectedCounty.county && item.city === selectedCity
      );
      setFilteredNames(newFilteredNames);
    } else if (selectedCounty) {
      const newFilteredNames = data.filter(
        (item: any) => item.county === selectedCounty.county
      );
      setFilteredNames(newFilteredNames);
    } else {
      setFilteredNames(data);
    }
  }, [selectedCounty, selectedCity, data]);

  useEffect(() => {
    let filtered = data;
    if (selectedCounty) {
      filtered = filtered.filter(
        (item: any) => item.county === selectedCounty.county
      );
    }
    if (selectedCity) {
      filtered = filtered.filter((item: any) => item.city === selectedCity);
    }
    if (selectedName) {
      filtered = filtered.filter((item: any) => item.name === selectedName);
    }
    setFilteredData(filtered);
  }, [selectedCounty, selectedCity, selectedName, data]);

  return (
    <Layout>
      <Helmet>
        <title>Lista Centre Promotie | {title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
      </Helmet>

      <div
        style={{
          minHeight: height + 20,
          paddingBottom: isDesktop ? "50px" : "40px",
        }}
        className="flex flex-col relative flex-grow items-center bg-secondary-grey pt-20 font-sans"
      >
        <div className="flex items-center justify-center flex-col lg:flex-row w-full space-y-3 lg:space-y-0 lg:space-x-40 px-8 md:px-24">
          <CountyDropdown setSelectedCounty={setSelectedCounty} data={data} />
          <CityDropdown
            setSelectedCity={setSelectedCity}
            data={filteredCities}
          />
          <NameDropdown
            setSelectedName={setSelectedName}
            data={filteredNames}
          />
        </div>
        <div
          style={{ height: isDesktop ? "none" : "500px" }}
          className="mx-5 md:mx-24 relative mt-8 flex overflow-x-auto w-full lg:w-auto px-2 lg:px-0 mb-10"
        >
          <table className="min-w-full w-full">
            <thead className="hidden sm:table-header-group bg-primary-green text-secondary-grey-light sticky top-0">
              <tr>
                <th className="px-8 py-3 text-center text-base lg:text-2xl font-medium uppercase tracking-wider">
                  Județ
                </th>
                <th className="px-8 py-3 text-center text-base lg:text-2xl font-medium uppercase tracking-wider">
                  Localitate
                </th>
                <th className="px-8 py-3 text-center text-base lg:text-2xl font-medium uppercase tracking-wider">
                  Nume Centru
                </th>
                <th className="px-8 py-3 text-center text-base lg:text-2xl font-medium uppercase tracking-wider">
                  Adresă
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData?.map((item: City, index) => (
                <tr
                  key={index}
                  className="bg-white border-t sm:border-t-[8px] border-b border-secondary-grey-light"
                >
                  <td className="flex justify-between text-right sm:table-cell sm:text-center pt-2 px-4 font-bold text-primary-green sm:text-base lg:text-xl">
                    <span className="pr-2 text-left text-primary-green sm:hidden">
                      Județ:
                    </span>
                    {item.county}
                  </td>
                  <td className="flex justify-between text-right sm:table-cell sm:text-center px-4 font-bold text-primary-green sm:text-base lg:text-xl">
                    <span className="pr-2 text-left text-primary-green sm:hidden">
                      Localitate:
                    </span>
                    {item.city}
                  </td>
                  <td className="flex justify-between text-right sm:table-cell sm:text-center px-4 font-bold text-primary-green sm:text-base lg:text-xl">
                    <span className="pr-2 text-left text-primary-green sm:hidden">
                      Nume Centru:
                    </span>
                    {item.name}
                  </td>
                  <td className="flex justify-between text-right sm:table-cell sm:text-center px-4 font-bold text-primary-green sm:text-base lg:text-xl lg:w-[500px] pb-2 lg:pb-0">
                    <span className="pr-2 text-left text-primary-green sm:hidden">
                      Adresă:
                    </span>
                    {item.address}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Disclaimer
          textColor="text-primary-green"
          image="/images/icons-green.png"
        />
      </div>
    </Layout>
  );
};

export default CenterLocations;
