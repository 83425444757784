import { Helmet } from "react-helmet";
import Layout from "../../Layout";
import { useWindowResizeEffect } from "../../hooks/resizeHeight";
import Disclaimer from "../../components/disclaimer";
import useDesktopCheck from "../../hooks/useDesktopCheck";

const Thankyou = () => {
  const height = useWindowResizeEffect();
  const isDesktop = useDesktopCheck();
  const title = "Caraiman";
  const description =
    "Vezi cum e viața la cabană și aplică învățăturile Cabanierului: când dai de greu pe traseu, stai puțin, trage-ți sufletul și încearcă un Caraiman rece.";
  return (
    <Layout>
      <Helmet>
        <title>Sa fie cu noroc | {title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
      </Helmet>
      <div
        style={{ minHeight: height + 20 }}
        className="bg-secondary-grey flex flex-col items-center text-primary-green text-center px-6 pb-24"
      >
        <img
          className="w-52 lg:w-72 mt-24"
          src="/images/noroc.png"
          alt="Doi oameni"
        />
        <h1 className="text-4xl lg:text-7xl font-extrabold tracking-wider mt-3">
          SĂ FIE CU NOROC!
        </h1>
        <h2 className="font-sans text-lg lg:text-4xl font-normal tracking-wider">
          Mulțumim că ai făcut un popas <br className="block lg:hidden" /> pe la
          noi și că te-ai înscris în <br />
          promoția Caraiman.
          <br className="block lg:hidden" /> Trage-ți sufletul în continuare.{" "}
          <br className="block lg:hidden" />
          <br className="hidden lg:block" />
          Orice cod înscris poate să te <br className="block lg:hidden" />
          aducă mai aproape de cabana ta.
        </h2>
        <h3 className="font-sans text-2xl lg:text-4xl font-bold tracking-wide leading-tight">
          Succes!
        </h3>
      </div>
      <Disclaimer
        textColor="text-primary-green"
        image="/images/icons-green.png"
      />
      <Disclaimer
        textColor={
          isDesktop ? "text-primary-green" : "text-secondary-grey-light"
        }
        shadow={isDesktop ? "" : "tail-shadow"}
        image={isDesktop ? "/images/icons-green.png" : "/images/icons2.png"}
      />
    </Layout>
  );
};

export default Thankyou;
