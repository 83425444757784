import React, { useRef } from "react";
import Layout from "../../Layout";
import SliderItems from "../../components/slider/slider-items";
import { useWindowResizeEffect } from "../../hooks/resizeHeight";
import { Helmet } from "react-helmet";
import Disclaimer from "../../components/disclaimer";

const Stories = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const iframeYtLinks = [
    "2GkZ3qjijsc",
    "OOpVU9CvkrQ",
    "iw3juFozoCs",
    "z_F-W6yZ1EQ",
    "XtnbDL7yPxg",
    "TRCLD7iSvC4",
    "ly1iQLGAxyo",
  ];
  const height = useWindowResizeEffect();
  const title = "Vezi toate filmulețele în care joacă berea Caraiman";
  const description =
    "Berea Caraiman nu se agită prea tare, dar dă foarte bine pe sticlă. Ia și tu un Caraiman rece și trage-ți sufletul în timp ce vezi reclamele de la cabană. ";

  return (
    <Layout>
      <Helmet>
        <title>Povestile | {title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
      </Helmet>
      <div
        className="flex flex-col items-center justify-center w-full bg-cover bg-center bg-no-repeat z-1"
        style={{
          backgroundImage:
            "url('/images/povestile/Caraiman_povestile_background.png')",
          minHeight: height + 20,
        }}
      >
        <h1 className="font-bold text-4xl uppercase text-white my-10 mx-8 text-center">
          Trage-ți sufletul și urmărește berea Caraiman în acțiune.
        </h1>
        <div className="relative  w-full max-w-xl md:max-w-2xl lg:max-w-3xl custom-lg:max-w-5xl mb-10">
          <SliderItems
            desktopSlidesView={1}
            tabletSlidesView={1}
            mobileSlidesView={1}
            prevChildArrow={
              <div
                ref={prevRef}
                className="absolute flex mx-1 md:mx-0 inset-y-0 left-0 md:-left-10 z-10 items-center cursor-pointer"
              >
                <img
                  className="swiper-button-prev w-6 md:w-8"
                  src="/images/raftul/Arrow_left.png"
                  alt=""
                />
              </div>
            }
            nextChildArrow={
              <div
                ref={nextRef}
                className="absolute flex mx-1 md:mx-0 inset-y-0 right-0 md:-right-10 z-10 items-center cursor-pointer"
              >
                <img
                  className="swiper-button-next w-6 md:w-8"
                  src="/images/raftul/Arrow_right.png"
                  alt=""
                />
              </div>
            }
            prevRef={prevRef}
            nextRef={nextRef}
            id="beers"
          >
            {iframeYtLinks.map((videoId, index) => (
              <iframe
                title={videoId}
                key={index}
                src={`https://www.youtube.com/embed/${videoId}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-full aspect-video" // 'aspect-video' creates a 16:9 aspect ratio. Ensure your project uses Tailwind CSS v2.2.0 or later.
              ></iframe>
            ))}
          </SliderItems>
        </div>
      </div>
      <Disclaimer
        textColor="text-secondary-grey-light"
        shadow="tail-shadow"
        image="/images/icons2.png"
      />
    </Layout>
  );
};

export default Stories;
