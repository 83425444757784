import { useRef, useState } from "react";
import "./App.css";
import Layout from "./Layout";
import { useWindowResizeEffect } from "./hooks/resizeHeight";
import { Helmet } from "react-helmet";
import useDesktopCheck from "./hooks/useDesktopCheck";
import Disclaimer from "./components/disclaimer";
import { useNavigate } from "react-router-dom";

function App() {
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const isDesktop = useDesktopCheck();
  const navigate = useNavigate();

  const toggleMute = () => {
    setIsMuted(!isMuted);
    if (audioRef.current) {
      audioRef.current.muted = !audioRef.current.muted;
    }
  };

  const soundImageSrc = isMuted
    ? "/images/home/sound_off.png"
    : "/images/home/sound.png";
  const soundImageAlt = isMuted ? "Porneste sunet" : "Opreste sunet";

  const height = useWindowResizeEffect();

  const title = "Berea Caraiman – Stai să-ți tragi sufletul";
  const description =
    "Încă un pas și ajungi la cabană. Am pus deja berea Caraiman la rece, deci te așteptăm să stai puțin și să-ți tragi sufletul cu o bere bună, cu gust echilibrat.";

  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
      </Helmet>
      <div
        className="flex relative w-full bg-cover bg-center bg-no-repeat home-bg"
        style={{
          backgroundImage: isDesktop
            ? "url('/images/home/Caraiman_Homepage_background.png')"
            : "url('/images/home/Caraiman_Homepage_background_mobile.png')",
          minHeight: height + 20,
        }}
      >
        {/* <img
          className="z-20 absolute lg:hidden bottom-0 right-5 max-h-[300px] custom-lg:right-64 custom-xl:right-80 custom-lg:max-h-[500px] custom-xl:max-h-[600px]"
          src="/images/home/personaj.png"
          alt="personaj"
        />
        <img
          className="z-10 absolute lg:hidden bottom-[20%] md:bottom-0 left-[1%] md:left-[20%] min-[1450px]:left-[20%] min-[1600px]:left-[30%] max-h-[210px] md:max-h-[250px] lg:max-h-[300px]"
          src="/images/home/valtoare.png"
          alt="valtoare"
        />
        <img
          className="z-2 absolute md:hidden bottom-[35%] -left-10 md:-left-10 min-[1450px]:left-[15%] min-[1600px]:left-[18%] max-h-[300px] lg:max-h-[650px]"
          src="/images/home/brad-langa-valtoare.png"
          alt="copac"
        /> */}
        <audio
          ref={audioRef}
          src="/audio/Caraiman_SFXsite.mp3"
          loop
          autoPlay
        ></audio>
        <button
          className="absolute z-[50] bottom-12 right-0 lg:bottom-8 lg:right-8"
          onClick={toggleMute}
        >
          <img
            className="max-w-[70%] lg:max-w-max"
            src={soundImageSrc}
            alt={soundImageAlt}
          />
        </button>
        <Disclaimer
          textColor="text-secondary-grey-light"
          shadow="tail-shadow"
          image="/images/icons2.png"
        />
      </div>
    </Layout>
  );
}

export default App;
