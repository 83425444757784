import Select from "react-select";
import { City } from "../county-dropdown/county-dropdown";
import {
  customStyles,
  customStylesMobile,
} from "../../hooks/customStyleSelect";
import useDesktopCheck from "../../hooks/useDesktopCheck";

const CityDropdown = ({ setSelectedCity, data }: any) => {
  const isDesktop = useDesktopCheck();
  const uniqueCounties = Array.from(
    new Set(data?.map((item: City) => item.city))
  ).sort();

  const options = uniqueCounties?.map((city) => ({
    value: city,
    label: city,
  }));

  const handleChange = (selectedOption: any) => {
    setSelectedCity(selectedOption ? selectedOption.value : null);
  };

  return (
    <Select
      options={options}
      onChange={handleChange}
      className="w-full uppercase text-center"
      placeholder="Caută localitate"
      isClearable={true}
      isSearchable={true}
      styles={isDesktop ? customStyles : customStylesMobile}
    />
  );
};
export default CityDropdown;
