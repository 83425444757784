import { Helmet } from "react-helmet";
import Layout from "../../Layout";
import { useWindowResizeEffect } from "../../hooks/resizeHeight";
import Disclaimer from "../../components/disclaimer";

const Chalet = () => {
  const myHtmlContent = `
  <p style="margin-bottom: 28px;">Lumea îl știe drept „Cabanierul” pentru că trăiește acolo sus, în liniștea muntelui. 
  Dar e mereu bucuros să primească oamenii veniți de jos, din agitația orașului. 
  Să le pună o bere în mână și o pauză în program. 
  </p>`;

  const myHtmlContent2 = `
  <p style="margin-bottom: 28px;"> „La dus ai mult de urcat, la întors ai mult de coborât. 
  Deci între fiecare du-te și fiecare vino al vieții, e bine să te mai și oprești un pic, să admiri peisajul, 
  să-ți aduni gândurile. Și să-ți tragi sufletul cu un Caraiman rece, cu gust echilibrat.”</p>`;

  const height = useWindowResizeEffect();
  const title = "Cabanierul nostru te așteaptă mereu cu un Caraiman rece";
  const description =
    "Vezi cum e viața la cabană și aplică învățăturile Cabanierului: când dai de greu pe traseu, stai puțin, trage-ți sufletul și încearcă un Caraiman rece.";
  return (
    <Layout>
      <Helmet>
        <title>Cabanierul | {title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
      </Helmet>
      <div
        className="flex items-center justify-center relative w-full bg-cover bg-center bg-no-repeat z-1 pb-40 md:pb-20 pt-20 "
        style={{
          backgroundImage: "url('/images/berea/berea_background.png')",
          minHeight: height + 20,
        }}
      >
        <div className="bg-secondary-grey w-full px-6 md:px-20 py-6 md:py-32 max-w-screen-xl mx-8 md:mx-20 relative ">
          <div className="flex flex-col lg:flex-row justify-start h-full max-[400px]:mb-32 mb-28 lg:mb-0">
            <div className="flex flex-col lg:max-w-[520px] custom-lg:max-w-[550px]">
              <p
                className="font-sans text-sm md:text-base lg:text-[22px] font-light text-primary-green"
                dangerouslySetInnerHTML={{ __html: myHtmlContent }}
              />
              <p
                className="font-sans text-sm md:text-base lg:text-[22px] font-semibold italic text-primary-green"
                dangerouslySetInnerHTML={{ __html: myHtmlContent2 }}
              />
            </div>
          </div>
        </div>

        <img
          className="absolute bottom-0 left-1/2 lg:left-[72%] custom-lg:left-[65%] transform -translate-x-1/2 max-[380px]:w-54 w-64 lg:w-[25rem] min-[1350px]:w-[30rem]"
          src="/images/home/personaj.png"
          alt="logo"
        />
      </div>
      <Disclaimer
        textColor="text-secondary-grey-light"
        shadow="tail-shadow"
        image="/images/icons2.png"
      />
    </Layout>
  );
};

export default Chalet;
