interface Props {
  onClick: () => void; // Renamed from 'handleOnClick' to 'onClick'
  title: string;
  description: string;
}

const GridItem = ({ onClick, title, description }: Props) => {
  return (
    <div
      onClick={onClick}
      className="bg-primary-green p-10 text-secondary-grey text-center max-w-[450px]"
    >
      <h2 className="text-3xl">{title}</h2>
      <div className="font-sans text-base mt-4 tracking-wide">
        {description}
      </div>
    </div>
  );
};

export default GridItem;
