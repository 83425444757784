// AIzaSyDOcYNuB8f2JuHUWw6kh7mCJyDZx1yd_j0
// loadGoogleMapsScript.js
export default function loadGoogleMapsScript(apiKey) {
  return new Promise((resolve, reject) => {
    // Check if the Google Maps API script is already loaded
    if (window.google && window.google.maps) {
      resolve(window.google.maps);
      return;
    }

    // Check for an existing script element and use it instead of creating a new one
    let script = document.querySelector(
      'script[src^="https://maps.googleapis.com/maps/api/js"]'
    );
    if (script) {
      // Existing script found, listen for its load event
      script.addEventListener("load", () => resolve(window.google.maps));
      script.addEventListener("error", (error) => reject(error));
    } else {
      // No existing script found, create a new one
      script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
      script.addEventListener("load", () => resolve(window.google.maps));
      script.addEventListener("error", (error) => reject(error));
    }
  });
}
