import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Contact from "./Contact";

const WrapperContact = () => {
  return (
    <div>
      <GoogleReCaptchaProvider
        reCaptchaKey={"6LehAaUpAAAAAGO8-W9BA58CCoexEcJ1sHU5gm2z"}
      >
        <Contact />
      </GoogleReCaptchaProvider>
    </div>
  );
};

export default WrapperContact;
