import { useEffect, useState } from "react";

export function useWindowResizeEffect() {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateHeight = () => {
      const header = document.getElementById("header");
      if (header) {
        const headerHeight = header.offsetHeight;
        const screenHeight = window.innerHeight;
        setHeight(screenHeight - headerHeight); // Update state with the new height
      } else {
        // Retry after a short delay if the header isn't found
        setTimeout(updateHeight, 100);
      }
    };

    // Call updateHeight initially and on each resize
    updateHeight();
    window.addEventListener("resize", updateHeight);

    // Cleanup function to remove the event listener and any pending timeouts
    return () => {
      window.removeEventListener("resize", updateHeight);
      // It's tricky to clear the specific timeout set inside updateHeight without external tracking.
      // Consider using a state variable or useRef to track timeout IDs if necessary.
    };
  }, []);

  return height; // Return the height from the hook
}
