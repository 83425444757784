import { useState } from "react";
import CountyDropdown, {
  City,
} from "../../components/county-dropdown/county-dropdown";
import Layout from "../../Layout";
import { Helmet } from "react-helmet";
import MapComponent from "../../components/winning-location-map/winning-location-map";
import { useWindowResizeEffect } from "../../hooks/resizeHeight";
import Disclaimer from "../../components/disclaimer";
import useDesktopCheck from "../../hooks/useDesktopCheck";
import { useFetchCities } from "../../mutations/get-cities";

const title = "Caraiman – gust echilibrat, numai bun să-ți tragi sufletul ";
const description =
  "Descoperă rețeta naturală a berii Caraiman cu gust echilibrat, inspirat de liniștea muntelui, ca să-ți tragi sufletul oricând simți nevoia de o pauză.";

const WinnerPrize = () => {
  const height = useWindowResizeEffect();
  const isDesktop = useDesktopCheck();
  const [selectedCounty, setSelectedCounty] = useState<City | null>(null);
  const { data, error } = useFetchCities("", true);

  return (
    <Layout>
      <Helmet>
        <title>Centre de premiere promotie | {title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
      </Helmet>

      <div
        style={{
          height: height + 20,
          paddingBottom: isDesktop ? "50px" : "40px",
        }}
        className="flex flex-col relative flex-grow items-center bg-secondary-grey pt-20"
      >
        <div className="relative flex flex-col items-center w-full lg:w-1/3 px-5 lg:px-0 mb-5 z-[48]">
          <p className="text-2xl mb-2 text-center text-primary-green uppercase">
            Alege un județ pentru a vedea centrele disponibile.
          </p>
          <CountyDropdown setSelectedCounty={setSelectedCounty} data={data} />
        </div>
        <MapComponent key={selectedCounty?.name} city={selectedCounty} />
        <Disclaimer
          textColor="text-primary-green"
          image="/images/icons-green.png"
        />
      </div>
    </Layout>
  );
};

export default WinnerPrize;
