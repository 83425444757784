import React, { ReactNode, useEffect, useState } from "react";

import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import styles from "./slider-items.module.scss";
import useDesktopCheck from "../../hooks/useDesktopCheck";
import cx from "classnames";

interface SliderItemsProps {
  id: string | number;
  children: ReactNode;
  desktopSlidesView: number;
  tabletSlidesView?: number;
  mobileSlidesView: number;
  prevChildArrow: ReactNode;
  nextChildArrow: ReactNode;
  isSmall?: boolean;
  extraItem?: boolean;
  prevRef?: React.RefObject<HTMLElement>;
  nextRef?: React.RefObject<HTMLElement>;
}

const SliderItems = ({
  id,
  children,
  isSmall,
  extraItem,
  desktopSlidesView,
  tabletSlidesView,
  mobileSlidesView,
  prevChildArrow,
  nextChildArrow,
  prevRef,
  nextRef,
  ...restProps
}: SliderItemsProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const isOnDesktop = useDesktopCheck();

  const swiperProps = {
    loop: true,
    navigation: { prevEl: prevRef?.current, nextEl: nextRef?.current },
    modules: [Navigation],
    onAfterInit: () => setIsLoading(false),
    id: `${id}`,
    spaceBetween: 5,
    slidesPerView: isOnDesktop ? desktopSlidesView : mobileSlidesView, // default for mobile
    breakpoints: {
      640: {
        slidesPerView: mobileSlidesView,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: tabletSlidesView,
        spaceBetween: 10,
      },
      1200: {
        slidesPerView: desktopSlidesView,
        spaceBetween: 30,
      },
    },
    ...restProps,
  };

  return (
    <div className={styles.containerSlider}>
      {prevChildArrow}
      {nextChildArrow}
      <Swiper className="relative mx-auto flex flex-row" {...swiperProps}>
        {React.Children.map(children, (child, index) => (
          <SwiperSlide
            className={cx(isSmall ? styles.itemSmall : styles.item, {
              [styles.hidden]: isLoading,
            })}
            key={`${id}-${index}`}
          >
            {child}
          </SwiperSlide>
        ))}
        {isOnDesktop && extraItem && React.Children.count(children) < 5
          ? Array.from(
              { length: 5 - React.Children.count(children) },
              (_, i) => <SwiperSlide key={`blank-${id}-${i}`} />
            )
          : null}
      </Swiper>
    </div>
  );
};

export default SliderItems;
