export const customStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "#2b513a",
    color: "white",
    borderColor: "#2b513a",
    boxShadow: "none",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "white",
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: "#2b513a",
    color: "white",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "white" : "#2b513a",
    color: state.isSelected ? "#2b513a" : "white",
    fontSize: "18px",
    ":hover": {
      backgroundColor: "white",
      color: "#2b513a",
      fontSize: "18px",
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#e1e0db",
    fontSize: "24px",
  }),
  input: (provided: any) => ({
    ...provided,
    color: "white",
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    color: "white",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "white",
  }),
};

export const customStylesMobile = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "#2b513a",
    color: "white",
    borderColor: "#2b513a",
    boxShadow: "none",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "white",
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: "#2b513a",
    color: "white",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "white" : "#2b513a",
    color: state.isSelected ? "#2b513a" : "white",
    fontSize: "16px",
    ":hover": {
      backgroundColor: "white",
      color: "#2b513a",
      fontSize: "16px",
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#e1e0db",
    fontSize: "16px",
  }),
  input: (provided: any) => ({
    ...provided,
    color: "white",
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    color: "white",
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "white",
  }),
};
