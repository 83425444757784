import { createBrowserRouter } from "react-router-dom";
import Beer from "./routes/Beer/Beer";
import Shelf from "./routes/Shelf/Shelf";
import Chalet from "./routes/Chalet/Chalet";
import Stories from "./routes/Stories/Stories";
// import Promotion from "./routes/Promotion/Promotion";
import App from "./App";
import WrapperContact from "./routes/Contact/WrapperContact";
import Promotion from "./routes/Promotion/Promotion";
import PromotionRules from "./routes/PromotionRules/PromotionRules";
import WinnerPrize from "./routes/WinnerPrize/WinnerPrize";
import CenterLocations from "./routes/CenterLocations/CenterLocations";
import Thankyou from "./routes/Thankyou/Thankyou";

export const router = createBrowserRouter([
  // Define a route that will render the App component when the URL matches
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/berea",
    element: <Beer />,
  },
  {
    path: "/raftul",
    element: <Shelf />,
  },
  {
    path: "/cabanierul",
    element: <Chalet />,
  },
  {
    path: "/povestile",
    element: <Stories />,
  },
  {
    path: "/promotia",
    element: <Promotion />,
  },
  {
    path: "/contact",
    element: <WrapperContact />,
  },
  {
    path: "/regulament-promotie",
    element: <PromotionRules />,
  },
  { path: "/promotia/centre-de-premiere", element: <WinnerPrize /> },
  { path: "/promotia/centre", element: <CenterLocations /> },
  { path: "/succes", element: <Thankyou /> },
  // {
  //   path: "/termeni-si-conditii",
  //   element: <TermsAndConditions />,
  // },
  // {
  //   path: "/politica-de-confidentialitate",
  //   element: <PrivacyPolicy />,
  // },
  // {
  //   path: "/regulamente-campanii",
  //   element: <CampaignRegulations />,
  // },
  // {
  //   path: "/cod-comunicare-comerciala",
  //   element: <CommercialCommunications />,
  // },
  // {
  //   path: "/anpc",
  //   element: <Anpc />,
  // },
]);
