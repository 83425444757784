import { useEffect, useState } from "react";

export default function useDesktopCheck() {
  const [isOnDesktop, setIsOnDesktop] = useState(false);

  const isWindowBiggerThan991 = () => {
    if (typeof window !== "undefined") {
      setIsOnDesktop(window?.innerWidth >= 1024);
    }
  };

  useEffect(() => {
    isWindowBiggerThan991();
    window.addEventListener("resize", isWindowBiggerThan991);
    return () => window.removeEventListener("resize", isWindowBiggerThan991);
  }, []);

  return isOnDesktop;
}
