import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
interface EmailData {
  name: string;
  company: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
}

export const useSendEmailMutation = () => {
  return useMutation({
    mutationFn: async (emailData: EmailData) => {
      return fetch("https://api.caraiman.ro/send-mail", {
        method: "POST",
        body: JSON.stringify(emailData),
      }).then((response) => {
        if (!response.ok) {
          toast.error("Eroare la trimiterea mesajului", {
            position: "bottom-center",
          });
          throw new Error("Failed to send email");
        }
        toast.success("Mesajul a fost trimis cu succes!", {
          position: "bottom-center",
        });
        return response.json();
      });
    },
  });
};
