import { useRef } from "react";
import Layout from "../../Layout";
import SliderItems from "../../components/slider/slider-items";
import { useWindowResizeEffect } from "../../hooks/resizeHeight";
import { Helmet } from "react-helmet";
import useDesktopCheck from "../../hooks/useDesktopCheck";
import Disclaimer from "../../components/disclaimer";

const Shelf = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const isDesktop = useDesktopCheck();
  const imageArray = [
    {
      img: "/images/raftul/Caraiman_033.png",
      style: "md:max-w-full ml-10 md:ml-0",
    },
    {
      img: "/images/raftul/Caraiman_05.png",
      style: "md:max-w-48 ml-10 md:ml-0",
    },
    {
      img: "/images/raftul/Caraiman_pahar.png",
      style: "md:max-w-48 ml-3 md:ml-0",
    },
    {
      img: "/images/raftul/Caraiman_doza.png",
      style: "md:max-w-[13rem] ml-5 md:ml-0",
    },
    {
      img: "/images/raftul/Caraiman_2l.png",
      style: "md:max-w-full ml-4 md:ml-0",
    },
    // ...add for looping swiper bug
    {
      img: "/images/raftul/Caraiman_033.png",
      style: "md:max-w-full ml-10 md:ml-0",
    },
    {
      img: "/images/raftul/Caraiman_05.png",
      style: "md:max-w-48 ml-10 md:ml-0",
    },
    {
      img: "/images/raftul/Caraiman_pahar.png",
      style: "md:max-w-48 ml-3 md:ml-0",
    },
    {
      img: "/images/raftul/Caraiman_doza.png",
      style: "md:max-w-[13rem]  ml-5 md:ml-0",
    },
    {
      img: "/images/raftul/Caraiman_2l.png",
      style: "md:max-w-full ml-4 md:ml-0",
    },
  ];

  const h1 = `La munte, la grătar, la terasă sau acasă?`;
  const h2 = `Cum alegi să-ți tragi sufletul,`;
  const h3 = `așa alegi și berea Caraiman de pe raft`;

  const height = useWindowResizeEffect();
  const title = "Aici sunt toate berile Caraiman, de la mic la mare ";
  const description =
    " Când ești la raft, gândește-te cum vrei să-ți tragi sufletul și alege ce fel de Caraiman o să pui la rece: la doză, la sticlă, la PET de 2,2 litri sau la bax.";

  return (
    <Layout>
      <Helmet>
        <title>Raftul | {title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
      </Helmet>
      <div
        className="flex flex-col items-center justify-center w-full bg-cover bg-center bg-no-repeat z-1"
        style={{
          backgroundImage: "url('/images/berea/berea_background.png')",
          minHeight: height + 20,
        }}
      >
        <div className="md:w-full mx-8 md:mx-0 md:max-w-2xl lg:max-w-3xl custom-lg:max-w-4xl mt-16 md:mt-20">
          <h1
            className="text-xl md:text-2xl lg:text-4xl font-semibold uppercase text-white text-center md:text-left"
            dangerouslySetInnerHTML={{ __html: h1 }}
          />
          <h2
            className="text-xl md:text-2xl lg:text-4xl font-semibold uppercase text-white text-center md:text-left"
            dangerouslySetInnerHTML={{ __html: h2 }}
          />
          <h3
            className="text-xl md:text-2xl lg:text-4xl font-semibold uppercase text-white text-center md:text-left"
            dangerouslySetInnerHTML={{ __html: h3 }}
          />
        </div>
        <div className="relative flex justify-center w-full max-w-40 md:max-w-xl lg:max-w-3xl custom-lg:max-w-4xl custom-xl:max-w-4xl mt-2 md:mt-0">
          <SliderItems
            desktopSlidesView={5}
            tabletSlidesView={2}
            mobileSlidesView={1}
            prevChildArrow={
              <div
                ref={prevRef}
                className="absolute flex inset-y-0 -left-20 lg:-left-28 custom-lg:-left-36 top-20 md:top-56 z-10 items-center cursor-pointer"
              >
                <img
                  className="swiper-button-prev w-6 md:w-8"
                  src="/images/raftul/Arrow_left.png"
                  alt=""
                />
              </div>
            }
            nextChildArrow={
              <div
                ref={nextRef}
                className="absolute flex inset-y-0 -right-20 lg:-right-28 custom-lg:-right-36 top-20 md:top-56 z-10 items-center cursor-pointer"
              >
                <img
                  className="swiper-button-next w-6 md:w-8"
                  src="/images/raftul/Arrow_right.png"
                  alt=""
                />
              </div>
            }
            prevRef={prevRef}
            nextRef={nextRef}
            id="beers"
          >
            {imageArray?.map((image, index) => {
              return (
                <img
                  className={`max-w-[75%] ${image.style}`}
                  key={index}
                  src={image.img}
                  alt="images"
                />
              );
            })}
          </SliderItems>
        </div>
        <img
          className="flex justify-start -mt-8 md:-mt-16 lg:-mt-10 custom-lg:-mt-10 w-full max-w-[1650px] object-cover mb-8 md:mb-0"
          src={
            isDesktop
              ? "/images/raftul/scandura.png"
              : "/images/raftul/scandura_mobile.png"
          }
          alt="scandura"
        />
      </div>
      <Disclaimer
        textColor="text-secondary-grey-light"
        shadow="tail-shadow"
        image="/images/icons2.png"
      />
    </Layout>
  );
};

export default Shelf;
